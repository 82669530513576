import styled from 'styled-components';

const InventoryStyles = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    max-width: 100%;
    width: 400px;

    table {
        width: 100%;
        thead {
            background: var(--red);
            color: #FFF;
        }
        tbody{
            tr td {
                padding: 0.5rem 1rem;
            }
            tr:nth-child(even) {
                background: rgba(0, 60, 118, 0.25);
            }
        }
    }

  @media (max-width: 900px) {
      font-size: 1.5rem;
  }
`;

export default InventoryStyles;