import React, { useState, useEffect }  from "react"
import InventoryStyles from '../styles/InventoryStyles';

var Airtable = require('airtable');
Airtable.configure({
    endpointUrl: 'https://api.airtable.com',
    apiKey: 'keyb3cmdgRSQ6bCN3'
});
var base = Airtable.base('appj3glVzELKCV6vo');

const HomePage = () => {

  // ----------------------
  // RUNTIME DATA FETCHING
  // ----------------------
  const [inventoryList, setInventoryList] = useState([{id: 1, name: "loading...", stock: "loading..."}]);

  useEffect(() => {

	base('Inventory').select({
		maxRecords: 100,
		fields: ["Name", "Current Stock"]
	}).eachPage(function page(records) {

		// Get all Inventory Items, filter any Name-less entries and sort alphabetically.
		const allItems = records.map((record) => {
			return { 
				id: record.getId(),
				name: record.get('Name'),
				stock: record.get('Current Stock')
			};
		}).filter(function(item){
			return item.name !== undefined;
		}).sort((a, b) => a.name.localeCompare(b.name));

		setInventoryList(allItems);

		}, function done(err) {
			if (err) { console.error(err); return; }
		});
	}, []);	

	return <>
		<div className="flex justify-center">
			<div>
				<InventoryStyles>
					<table>
						<thead>
							<tr>
								<td>Items</td>
								<td>Stock</td>
							</tr>
						</thead>
						<tbody>
							{inventoryList.map((item, index) => {
								return (
									<tr key={item.id}>
										<td>{item.name}</td>
										<td>{item.stock}</td>
									</tr>
								)
							})}
						</tbody>
					</table>
				</InventoryStyles>
			</div>
		</div>
	</>
}

export default HomePage
